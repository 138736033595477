import DeleteObject from '@/layouts/Stack/components/DeleteObject';
import Vue from 'vue';
import modals from '@/mixins/modals';
export default {
  mixins: [modals],
  data() {
    return {
      count: '',
      isSuccessDeleted: false,
      params: {},
    };
  },
  methods: {
    deleteObjects(storage) {
      const that = this;
      return new Promise(() => {
        this.$modals.open({
          name: 'DeleteObject',
          size: 'medium',
          component: DeleteObject,
          closeOnBackdrop: false,
          props: { storage: storage },
          on: {
            change: data => {
              this.params = Object.assign({}, data.storage);
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('confirm'), color: 'error' },
              on: {
                click: async () => {
                  Vue.set(this.modal.footer.confirm.props, 'loading', true);
                  await this.$store
                    .dispatch('moduleStack/deleteS3Object', this.params)
                    .then(data => {
                      // console.log(data.$metadata.httpStatusCode);
                      if (data.$metadata.httpStatusCode === 204) {
                        this.isSuccessDeleted = true;
                      }
                      that.$modals.close();
                    })
                    .catch(e => e);
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
  },
};
