<template>
  <div class="vps-detail">
    <Toast position="top-right" />
    <transition name="slide-fade">
      <page-block-stack :title="' '" class="vps-detail-backup__block">
        <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
        <page-block v-else-if="!isRequest" style="margin-top: 0">
          <!--            state-storage="local"-->
          <!--            :state-key="stateKey"-->
          <DataTable
            :always-show-paginator="true"
            :value="objects"
            ref="objects"
            :paginator="true"
            :loading="loader"
            :expanded-rows.sync="expandedRows"
            @row-expand="onRowExpand"
            @row-collapse="onRowCollapse"
            data-key="key"
            @page="onPage"
            @sort="onSort"
            :show-gridlines="false"
            :show-clear-button="true"
            :rows-per-page-options="[10, 25, 50, 100]"
            :row-hover="true"
            removable-sort
            :filters.sync="filters"
            :global-filter-fields="['uniqueName', 'path', 'size', 'date']"
            class="p-datatable p-datatable-sm"
            responsive-layout="scroll"
            current-page-report-template="({currentPage} из {totalPages})"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rows="10"
          >
            <template #header>
              <div class="filter">
                <div class="filter-upload">
                  <FileUpload
                    ref="upload"
                    mode="basic"
                    :name="file && file.name ? file.name : null"
                    :multiple="false"
                    :auto="false"
                    :max-file-size="10 * 1024 ** 3"
                    choose-label="Добавить"
                    :custom-upload="true"
                    :show-cancel-button="true"
                    :invalid-file-size-message="
                      'Вы можете загрузить файл размером до 10ГБ.\n' +
                      'Файлы более 10ГБ можно загрузить через CLI или приложение для работы с S3.'
                    "
                    button-class="p-button-sm"
                    button-style="upload-button"
                    @progress="progressLoad($event)"
                    @select="select($event)"
                    @clear="clearFile($event)"
                    @before-upload="view($event)"
                    @uploader="myUploader"
                  />
                  <Button
                    v-if="fileName && !loader"
                    type="button"
                    icon="pi pi-times"
                    class="p-button-danger p-button-sm filter-upload__btn"
                    @click="$refs.upload.clear()"
                  ></Button>
                  <!--                  <plain-button-->
                  <!--                    v-if="!fileName"-->
                  <!--                    color="primary"-->
                  <!--                    nowrap-->
                  <!--                    class="filter-upload__btn"-->
                  <!--                    @click="newFolder({ storage: tariff, storageid: storageid, folders: folders })"-->
                  <!--                  >-->
                  <!--                    {{ $t('addFolder') }}-->
                  <!--                  </plain-button>-->
                </div>
                <div v-if="progress" class="filter-progress">
                  <base-progress
                    v-if="progress"
                    :value="progress"
                    :text="' '"
                    type="indeterminate"
                  ></base-progress>
                </div>
                <div class="filter-search">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Поиск..." />
                  </span>
                </div>
              </div>
            </template>
            <template #empty> Нет объектов. </template>
            <template #loading> Идет загрузка информации... </template>
            <Column
              filter-field="uniqueName"
              :exclude-global-filter="true"
              field="uniqueName"
              header="Имя"
              :styles="{ width: '38%' /*, 'padding-left': '80px'*/ }"
              :sortable="true"
            >
              <template #body="slotProps">
                <i :class="`pi ${slotProps.data.isFolder ? 'pi-folder' : 'pi-file'}`" />
                {{ slotProps.data.uniqueName }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по имени"
                />
              </template>
            </Column>
            <Column field="path" header="Префикс" :styles="{ width: '38%' }" :sortable="true">
              <template #body="slotProps">
                {{ slotProps.data.path }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по префиксу"
                />
              </template>
            </Column>
            <Column field="size" header="Размер" :styles="{ width: '8%' }" :sortable="true">
              <template #body="slotProps">
                {{ slotProps.data.isFolder ? '' : size(slotProps.data) }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по размеру"
                />
              </template>
            </Column>
            <Column field="date" header="Дата" :styles="{ width: '12%' }" :sortable="true">
              <template #body="slotProps">
                {{ created(slotProps.data) }}
              </template>
              <template #filter="{ filterModel }">
                <InputText
                  type="text"
                  v-model="filterModel.value"
                  class="p-column-filter"
                  placeholder="Поиск по дате"
                />
              </template>
            </Column>
            <template #expansion="slotProps">
              <div class="container">
                <div class="container-info">
                  <div class="container-info__el">
                    <label class="standart-title">Класс хранилища:</label>
                    <label class="standart-text">{{ slotProps.data.class }}</label>
                  </div>
                  <div class="container-info__el">
                    <label class="standart-title">Content type:</label>
                    <label class="standart-text">{{ slotProps.data.type }}</label>
                  </div>
                  <div class="container-info__permission">
                    <label class="standart-title">Права ACL:</label>
                    <div
                      v-for="(category, i) of checkedACL[slotProps.data.key]"
                      :key="category.k"
                      class="field-checkbox"
                    >
                      <label class="standart-text">{{ $t(i) }}</label>
                      <chips :list="checkedACL[slotProps.data.key][i]"></chips>
                    </div>
                  </div>
                  <div class="container-info__el">
                    <label class="standart-title">ETag:</label>
                    <label class="standart-text">{{ slotProps.data.eTag }}</label>
                  </div>
                </div>

                <div class="buttons-context">
                  <Button
                    v-if="!slotProps.data.isFolder"
                    type="linc"
                    label="Скачать"
                    icon-pos="right"
                    icon="pi pi-download"
                    class="p-button-rounded p-button-text"
                    @click="viewInfo(slotProps.data.key)"
                  ></Button>
                  <Button
                    v-if="isSharedLinced(slotProps.data.key) && !slotProps.data.isFolder"
                    type="linc"
                    label="Скопировать ссылку"
                    icon="pi pi-external-link"
                    icon-pos="right"
                    class="p-button-rounded p-button-text"
                    @click="copyLink(slotProps.data.key)"
                  />
                  <div v-if="!isSharedLinced(slotProps.data.key) && !slotProps.data.isFolder">
                    <transition name="fade" mode="out-in">
                      <div v-if="isLinkCreatedObj[slotProps.data.key]">
                        <create-link :link-expired="linkExpired[slotProps.data.key]" />
                      </div>
                      <Button
                        v-else
                        type="linc"
                        label="Поделиться"
                        icon="pi pi-share-alt"
                        icon-pos="right"
                        class="p-button-rounded p-button-text"
                        @click="createS3Link(slotProps.data.key)"
                      />
                    </transition>
                  </div>
                  <Button
                    v-if="slotProps.data.isFolder"
                    type="linc"
                    label="Загрузить файл"
                    icon="pi pi-upload"
                    icon-pos="right"
                    class="p-button-rounded p-button-text p-button-еуче"
                    @click="putFileInFolder(slotProps.data.key)"
                  ></Button>
                  <Button
                    type="linc"
                    label="Удалить"
                    icon="pi pi-trash"
                    icon-pos="right"
                    class="p-button-rounded p-button-text p-button-danger"
                    @click="deleteObject(slotProps.data.key)"
                  ></Button>
                </div>
              </div>
            </template>
            <Column :expander="true" :header-style="{ width: '4%' }" />
            <!--            <template #paginatorend>-->
            <!--              <Button-->
            <!--                type="button"-->
            <!--                label="Свернуть"-->
            <!--                icon="pi pi-minus"-->
            <!--                icon-pos="right"-->
            <!--                class="p-button-text"-->
            <!--                @click="collapseAll"-->
            <!--              />-->
            <!--            </template>-->
            <!--            <template #paginatorstart>-->
            <!--              <Button-->
            <!--                type="button"-->
            <!--                label="Развернуть"-->
            <!--                icon="pi pi-plus"-->
            <!--                class="p-button-text"-->
            <!--                @click="expandAll"-->
            <!--              />-->
            <!--            </template>-->
            <!--            <template #footer>-->
            <!--              <div class="datatable-footer">-->
            <!--                <Button-->
            <!--                  icon="pi pi-sync"-->
            <!--                  v-tooltip.top="'Обновить'"-->
            <!--                  class="p-button-sm"-->
            <!--                  @click="updateStorageInfo()"-->
            <!--                />-->
            <!--                <Button-->
            <!--                  icon="pi pi-eraser"-->
            <!--                  v-tooltip.top="'Очистить настройки'"-->
            <!--                  class="p-button-sm"-->
            <!--                  @click="clearLocalSettings()"-->
            <!--                />-->
            <!--              </div>-->
            <!--            </template>-->
          </DataTable>
        </page-block>
        <router-view></router-view>
      </page-block-stack>
    </transition>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import { OPStorage } from '@/models/OP/OPStorage';
import PageBlockStack from '@/components/PageBlock/PageBlockStack';
import newFolder from '@/mixins/newFolder';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import popoverFix from '@/mixins/popoverFix';
import Column from 'primevue/column';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';

import Toast from 'primevue/toast';
import deleteObjects from '@/mixins/deleteObjects';
import Vue from 'vue';
import viewObject from '@/mixins/viewObject';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import modals from '@/mixins/modals';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { GetObjectCommand, PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import Tooltip from 'primevue/tooltip';
import Chips from '@/components/Chips/Chips';
import CreateLink from '@/layouts/Stack/components/CreateLink';
import createS3Link from '@/layouts/Stack/mixins/createS3Link';
import FileUpload from 'primevue/fileupload/FileUpload';
import BaseProgress from '@/components/BaseLoader/BaseProgress';

export default {
  name: 'Objects',
  components: {
    CreateLink,
    BaseProgress,
    DataTable,
    Column,
    Chips,
    PageBlockStack,
    InputText,
    // InputNumber,
    // MaskedInput,
    // Calendar,
    // InputMask,
    Toast,
    FileUpload,
    Button,
  },
  mixins: [providerChange, newFolder, viewObject, popoverFix, createS3Link, modals, deleteObjects],
  beforeRouteLeave(_to, _from, next) {
    this.collapseAll();
    next();
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    storageid: {
      type: String,
      default: '',
    },
    tariff: {
      type: OPStorage,
      required: true,
      validator: obj => obj instanceof OPStorage,
    },
  },
  data() {
    return {
      isRequest: false,
      linkExpired: {},
      prefixFolderForUploadInFolder: null,
      isLinkCreatedObj: {},
      defaultLinkExpired: {
        d: 0,
        h: 1,
        m: 0,
        s: 0,
      },
      minDate: null,
      maxDate: null,
      invalidDates: null,
      linkTime: '01:00:00',
      acl: ['FULL_CONTROL', 'READ', 'WRITE', 'WRITE_ACP', 'READ_ACP'],
      expandedRows: [],
      objectsACL: {},
      selectedObjectsACL: {},
      checkedACL: {},
      currentObject: '',
      fileName: '',
      download: false,
      file: {},
      type: 'determinate',
      totalSize: 100,
      progress: 0,
      mode: '',
      loader: false,
      filters: {
        global: { value: null },
        uniqueName: { value: null },
        path: { value: null },
      },
      folders: [],
      isCurrentLoading: false,
      isMobile: true,
    };
  },
  computed: {
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
    objects() {
      return this.tariff.objects;
    },
    stateKey() {
      return `dt-state-local-${this.storageid}`;
    },
    isPublic() {
      return this.tariff.policy.IsPublic;
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    // isSelected() {
    //   return !!Object.keys(this.selectedItems) && Object.keys(this.selectedItems).length > 0;
    // },
    // onlySelectedItems() {
    //   // this.actuallySelectedFiles
    //   const keys = Object.keys(this.selectedItems);
    //   if (!keys) return null;
    //   else {
    //     const names = keys.filter(key => this.selectedItems[key].checked);
    //     return names.map(x => {
    //       const type = this.objects.find(item => x === item.name);
    //       const obj = {
    //         name: x,
    //         type: !type ? 'folder' : 'file',
    //       };
    //       // console.log('onlySelectedItems', obj);
    //       return obj;
    //     });
    //   }
    // },
    // actuallySelectedFiles() {
    //   // console.log(
    //   //   'objects',
    //   //   this.objects.map(x => x.name)
    //   // );
    //   // console.log(
    //   //   'actuallySelectedFiles',
    //   //   this.onlySelectedItems.map(x => x.name)
    //   // );
    //
    //   return this.objects
    //     .map(x => x.name)
    //     .filter(value => this.onlySelectedItems.map(x => x.name).includes(value)).length;
    // },

    // isBufferUse() {
    //   if (!this.s3bufferStorage) return null;
    //   else
    //     return !!Object.keys(this.s3bufferStorage) && Object.keys(this.s3bufferStorage).length > 0;
    // },
    // lengthBufferStorage() {
    //   return this.isBufferUse ? Object.keys(this.s3bufferStorage.items).length : null;
    // },
    // authOp() {
    //   return localStorage.getItem('stack');
    // },
  },
  watch: {
    isSuccessDeleted: {
      handler: async function (event) {
        // console.log(event);
        // console.log(this.currentObject);
        if (event) {
          this.$toast.add({
            severity: 'success',
            summary: 'Объект удален',
            detail: this.currentObject,
            life: 5000,
          });
          this.currentObject = '';
          this.isSuccessDeleted = false;
          await this.updateStorageInfo();
        }
      },
    },
    // nodeSelect: {
    //   handler: function (event) {
    //     // console.log('-----nodeSelect-----', event.key, event.parent_id);
    //     let newKey;
    //     if (event.parent_id && event.key) {
    //       newKey = event.key.split('/');
    //       newKey.pop();
    //       newKey.join('/');
    //     }
    //     // const key = event.parent_id ? event.parent_id.replaceAll('-', '/') : null;
    //     const key = event.parent_id ? newKey.join('/') : null;
    //
    //     if (key) {
    //       // console.log(key);
    //       const resultArray = [];
    //       key
    //         .split('/')
    //         .forEach((number, index) =>
    //           resultArray.push((resultArray[index - 1] || '') + '/' + number)
    //         );
    //       const result = resultArray.map(x => x.slice(1));
    //       // console.log(this.selectedItems);
    //       // console.log(result);
    //
    //       if (result)
    //         result.forEach(item => {
    //           // console.log(this.folders.filter(x => x.type === 'object').map(x => x.name));
    //           if (
    //             this.folders
    //               .filter(x => x.type === 'object')
    //               .map(x => x.name)
    //               .includes(item)
    //           ) {
    //             // console.log(item);
    //             this.selectedItems[item].checked = false;
    //             this.selectedItems[item].partialChecked = true;
    //           }
    //         });
    //     }
    //   },
    //   deep: true,
    // },
    // download: {
    //   handler: function (event) {
    //     console.log('this.download', event);
    //   },
    //   immediate: true,
    // },
    screenWidth: function () {
      this.setIsMobile();
    },
    dataToRender(event) {
      // console.log(event);
    },
    progress(event) {
      if (event) {
        this.$store.state.moduleStack.progressBar = event;
      }
      if (event === this.$store.state.moduleStack.totalSize) {
        setTimeout(() => {
          this.$store.state.moduleStack.progressBar = 0;
          this.$store.state.moduleStack.totalSize = 0;
        }, 500);
      }
    },
    objects: {
      handler: function (event) {
        // console.log(event);
        if (event) {
          this.folders = [];
          this.folders.push({ name: '/', type: 'object' });
          event.forEach(node => {
            // console.log(node);
            const name = node.key;
            const isFolder = node.key.endsWith('/');
            if (isFolder) {
              this.folders.push({ name: name, type: 'object' });
            }
          });
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    this.collapseAll();
    this.updateStorageInfo();
    // this.onPage();
    // this.expandAll();
    // this.getBufferStorage();
  },

  beforeDestroy() {
    this.collapseAll();
  },
  methods: {
    async sendUrl(item) {
      const config = {
        region: 'ru-msk',
        endpoint: 'https://s3.rusonyxcloud.ru',
        credentials: {
          accessKeyId: this.s3key.access,
          secretAccessKey: this.s3key.secret,
        },
      };
      const client = await new S3Client(config);

      const input = {
        Bucket: this.storageid,
        Key: item,
      };

      async function getSignedDownloadUrl() {
        let command = new PutObjectCommand(input);
        return await getSignedUrl(client, command, { expiresIn: 3600 });
      }

      // console.log(await getSignedDownloadUrl());
      return await getSignedDownloadUrl();
    },
    isSharedLinced(item) {
      return !!(this.checkedACL[item] && this.checkedACL[item]['READ']?.includes('all'));
    },
    async updateStorageInfo() {
      // console.log('update');
      this.loader = true;
      // const params = {
      //   key: this.s3key,
      //   bucket: this.storageid,
      // };
      await this.$store
        .dispatch('moduleStack/fetchCurrentStorages', this.storageid)
        .finally(() => (this.loader = false));
    },
    view(event) {
      // console.log(event);
    },
    onPage(event) {
      this.collapseAll();
      // console.log('onPage---------', event);
    },
    onSort(event) {
      // console.log('onSort---------', event);
    },
    valueChange(event) {
      // console.log('valueChange---------', event);
    },
    //   this.loading = true;
    //
    //   //imitate delay of a backend call
    //   // setTimeout(() => {
    //   this.nodes = this.nodes(event.first, this.rows);
    //   this.loading = false;
    //   // }, 1000);
    // },
    // nameFolder({ name }) {
    //   return name;
    // },
    clearFile() {
      this.fileName = '';
      // console.log(event);
    },
    async select(event) {
      // console.log(event);
      this.file = event.files[0];
      this.totalSize = this.file.size;
      this.fileName = event && event.files && event.files.length ? event.files[0].name : '';
      // console.log('prefixFolderForUploadInFolder', this.prefixFolderForUploadInFolder);
      if (this.prefixFolderForUploadInFolder) {
        this.fileName = this.prefixFolderForUploadInFolder + this.fileName;
        await this.myUploader();
      }
      // console.log('fileName', this.fileName);
      let findNameInArray = !!this.objects.find(x => x.name === this.fileName);
      // console.log('findNameInArray', findNameInArray);
      if (findNameInArray) {
        this.showResModal(
          'Объект с таким именем уже существует. <br> При загрузке файл обновит текущий объект.'
        );
      }
    },
    progressLoad(event) {},
    size(item) {
      if (item && item.size) return objectSizeParser(item.size);
    },
    showResModal(res) {
      if (!this.modal) this.makeModal();
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'html', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        // centered: true,
        confirm: {
          props: { title: this.$t('confirm') },
          on: {
            click: async () => {
              this.$modals.close();
              if (this.prefixFolderForUploadInFolder) await this.myUploader();
            },
          },
        },
        cancel: {
          props: {
            title: 'Отменить',
            loading: false,
          },
          on: {
            click: () => {
              this.$modals.close();
              this.$refs.upload.clear();
            },
          },
        },
      });
    },
    async myUploader() {
      this.loader = true;
      // this.fileName = '';
      // this.prefixFolderForUploadInFolder = null;
      this.$store.state.moduleStack.totalSize = 0;
      this.$store.state.moduleStack.progressBar = 0;
      let formData = new FormData();
      this.$store.state.moduleStack.totalSize = this.totalSize;
      formData.append(this.fileName, this.file);
      const url = await this.sendUrl(this.fileName);
      const params = {
        url: url,
        storage: this.storageid,
        file: this.file,
        name: this.file.name,
      };
      await this.onUpload(params)
        .then(data => {
          if (data === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Объект загружен',
              detail: this.file.name,
              life: 3000,
            });
          } else {
            this.$toast.add({
              // severity: 'clear',
              severity: 'error',
              summary: 'Ошибка',
              detail: this.file.name,
              life: 3000,
            });
          }
        })
        .finally(() => {
          // console.log('finally');
          this.progress = 0;
          this.fileName = '';
          this.prefixFolderForUploadInFolder = null;
          this.file = [];
          if (this.$refs?.upload) this.$refs.upload.clear();
          this.collapseAll();
          this.loader = false;
        });
    },
    viewSelected(item) {
      if (item) return Object.keys(item).length;
      else return 0;
    },
    created(i) {
      // console.log(i);
      if (i && i.date) return format(i.date, 'dd.MM.yyyy, HH:mm');
    },
    async deleteObject(item) {
      const params = {
        bucket: this.storageid,
        name: item,
      };
      this.currentObject = item;
      return await this.deleteObjects(params);
    },
    copyLink(item) {
      const url = `https://s3.rusonyxcloud.ru/${this.storageid}/${item}`;
      this.$clipboard(url);
      this.showSuccessModal('Ссылка для скачивания успешно скопирована в буфер обмена.');
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'medium',
        text,
      });
    },
    async onRowExpand(event) {
      const id = event?.data?.key ? event?.data?.key : event;
      // console.log(id);
      const acl = await this.getObjectACL(id);
      const owner = acl.Owner.ID;
      const checkedAcl = {};
      const checkedPermissions = {};
      this.acl.forEach(a => {
        acl.Grants.forEach(x => {
          if (a === x.Permission) {
            if (x.Grantee?.ID === owner) {
              if (!checkedAcl.owner) checkedAcl.owner = [];
              if (!checkedPermissions[x.Permission]) checkedPermissions[x.Permission] = [];
              checkedAcl.owner.push({ v: x.Permission, k: 'owner' });
              // checkedPermissions[x.Permission].push({ k: 'owner', v: 'acl' });
              checkedPermissions[x.Permission].push('owner');
            } else {
              if (x.Grantee?.URI.includes('AllUsers')) {
                if (!checkedPermissions[x.Permission]) checkedPermissions[x.Permission] = [];
                if (!checkedAcl.all) checkedAcl.all = [];
                checkedAcl.all.push({ v: x.Permission, k: 'all' });
                // checkedPermissions[x.Permission].push({ k: 'all', v: 'acl' });
                checkedPermissions[x.Permission].push('all');
              }
              if (x.Grantee?.URI.includes('AuthenticatedUsers')) {
                if (!checkedPermissions[x.Permission]) checkedPermissions[x.Permission] = [];
                if (!checkedAcl.auth) checkedAcl.auth = [];
                checkedAcl.auth.push({ v: x.Permission, k: 'auth' });
                // checkedPermissions[x.Permission].push({ k: 'auth', v: 'acl' });
                checkedPermissions[x.Permission].push('auth');
              }
            }
          }
        });
      });
      this.checkedACL[id] = checkedPermissions;
      await this.getObjectHead(id);
    },
    onRowCollapse(event) {},
    expandAll() {
      const list = this.$refs.objects.dataToRender;
      this.expandedRows = list.filter(p => p.name);
      this.expandedRows.forEach(x => this.onRowExpand(x.key));
    },
    collapseAll() {
      this.expandedRows = null;
    },
    async getObjectACL(item) {
      const params = {
        bucket: this.storageid,
        // ExpectedBucketOwner: 'vdc368550',
        name: item,
        key: this.s3key,
      };
      return await this.$store.dispatch('moduleStack/getObjectACL', params);
    },
    async getObjectHead(item) {
      const params = {
        bucket: this.storageid,
        // ExpectedBucketOwner: 'vdc368550',
        name: item,
        key: this.s3key,
      };
      return await this.$store.dispatch('moduleStack/getObjectHead', params);
    },
    clearLocalSettings() {
      localStorage.removeItem(this.stateKey);
      this.updateStorageInfo();
    },
    async putFileInFolder(item) {
      // console.log(this.$refs.upload);
      this.prefixFolderForUploadInFolder = item;
      // console.log('putFileInFolder', item);
      this.$refs.upload.onBasicUploaderClick();
    },
    async getObject(item) {
      const params = {
        bucket: this.storageid,
        name: item,
        // key: this.s3key,
      };
      return this.$store.dispatch('moduleStack/getObject', params).then(async data => {
        // console.log(data);
        const chunks = [];
        const writer = new WritableStream({
          write(chunk) {
            chunks.push(chunk);
          },
          close() {
            const name = item.includes('/') ? item.split('/').at(-1) : item;
            const blob = new Blob(chunks);
            saveAs(blob, name);
          },
        });
        return data.Body.pipeTo(writer);
      });
    },
    async viewInfo(item) {
      // console.log(item);
      // await this.createSession();
      const config = {
        region: 'ru-msk',
        endpoint: 'https://s3.rusonyxcloud.ru',
        credentials: {
          accessKeyId: this.s3key.access,
          secretAccessKey: this.s3key.secret,
        },
      };
      const client = await new S3Client(config);

      const input = {
        Bucket: this.storageid,
        Key: item,
      };

      async function getSignedDownloadUrl() {
        let command = new GetObjectCommand(input);
        return await getSignedUrl(client, command, { expiresIn: 3600 });
      }
      const url = await getSignedDownloadUrl();
      window.open(url, '_blanc');
      const name = item.includes('/') ? item.split('/').at(-1) : item;
    },

    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    showLoadingModal(props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'text', props);
      Vue.set(this.modal, 'title', props);
      Vue.set(this.modal, 'component', BaseLoader);
      Vue.set(this.modal, 'closable', false);
      Vue.set(this.modal, 'footer', false);
    },

    async onUpload(payload) {
      const params = {
        onUploadProgress: progressEvent => {
          this.progress = progressEvent.loaded;
        },
        ...payload,
      };
      return this.$store
        .dispatch('moduleStack/sendFile', params)
        .then(async data => {
          // console.log(data);
          await this.updateStorageInfo();
          return data;
        })
        .catch(e => {
          console.log(e);
          let error;
          error =
            e.response.data.Code === 'QuotaExceeded'
              ? 'Превышена квота по количеству объектов. Пожалуйста увеличьте квоту или обратитесь в техподдержку.'
              : 'Произошла ошибка, попробуйте повторить позднее, либо обратитесь в техподдержку';
          this.showError(error);
          return 'error';
        });
    },
    async updateStorage() {
      await this.getCurrentStorage();
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "addFolder": "Добавить папку",
      "add_file": "Загрузить файлы",
      "object": " Выделено 0 объектов | Выделен {n} объект | Выделены {n} объекта | Выделено {n} объектов",
      "buffer": "0 объектов | {n} объект | {n} объекта | {n} объектов",
      "new": "Создать папку",
      "select": "Выбрать",
      "FULL_CONTROL": "Полный доступ ",
      "READ": "Чтение ",
      "READ_ACP": "Чтение ACL ",
      "sure": {
        "create": "Создать",
        "successLink": "Ссылка сгенерирована и скопирована в буфер обмена."
      },
      "WRITE": "Запись ",
      "WRITE_ACP": "Запись ACL ",
      "delete": "Удалить объекты",
      "deselect": "Очистить",
      "tableHead": {
        "name": "Файл",
        "size": "Размер",
        "date": "Дата создания",
        "shared": "Публичная",
        "config": "Конфигурация",
        "conditions": "",
        "status": "Статус",
        "state": "Состояние",
        "port": "Порты",
        "menu": "Меню"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;

.action-buttons {
  display: flex;
  //flex-direction: column
}
.container {
    display flex
    justify-content: space-between
    align-items: baseline
    flex-direction: row;
    +breakpoint(xs-only) {
      flex-direction column
    }
  &-info{
    width: 80%
    display: flex;
    align-items: baseline
    justify-content: space-between
    flex-direction: row;
  +breakpoint(ml-and-down) {
    flex-direction column
  }


  &__el {
      //margin: auto 1rem
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;

    & + & {
      margin-bottom: 0.5rem
      margin-top: 0.5rem
    }

  }
  &__permission {
      //margin: auto 0.5rem
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      justify-content: space-evenly;
      align-items: baseline;
  }
}
}
.time {
  width: 10rem;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem

  &__select {
    width: 100%;
    margin: 1rem 0.65rem 0.5rem 0.65rem;

    //margin: auto 1rem auto 0.65rem;
    display: flex;
    //justify-content: space-between;
    align-items: center;
    &-selected {
      margin-left: 1rem;
    }

    &-buttons {

      &__btn {
        margin: auto 0.5rem;
      }
    }

  }
}
.field-checkbox{
  display flex
}
.time-buttons {
  width: 3rem
  min-width: 3rem;
}
.header-bottom{
  display flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: flex-start;


  &__start {
    margin auto 1rem
    min-width: 10rem
  }
  &__second {
    margin: auto 1rem
    min-width: 10rem
    max-width: 15rem
    display flex
    flex-direction: row;

  }
  &__progress {
    margin: auto 1rem
    width: 100%
    text-align: end;
  }
}
.buttons-context{
    display flex;
    flex-direction column;
    justify-content flex-end;
  align-items: flex-end;
  }
.p-datatable-header-up{
  background: #fff;
  border: 0;
}
.datatable-footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 1rem;
}
.filter{
  display: flex;
  justify-content: space-between;
  //justify-content: flex-end;
  margin-bottom: 0.75rem;
      flex-wrap:wrap;
  align-items: center;
    +breakpoint(ms-and-down) {
    }
  &-upload{
    margin-right: 2rem
    display: flex;
    flex-direction: row;
    //width: 10%
    //order: 1;
    +breakpoint(ml-and-down) {
    order: 1;
    }
    &__btn{
      margin-left: 1rem;

    }
    & + & {
      margin-left: 2rem
    }

  }
  &-progress {
    margin-right: 2rem;
    flex-grow: 2
    //width: 50%
    max-width: 700px
    //order: 2;
    //+breakpoint(ml-only) {
    //  margin-top: 0.5rem;
    //  width: 100%
    //  order: 3;
    //}
    +breakpoint(ml-and-down) {
      margin-top: 0.5rem;
      width: 100%
      order: 3;
    }
  }

  &-search{
    +breakpoint(ml-and-down) {
      margin-top: 0.5rem;
      order: 2;
    }
    //+breakpoint(ms-and-down) {
    //  order: 3;
      //margin-top: 0.5rem;
      //order: 2;
      //width: 45%
    //}
  }
  & + & {
    margin-left: 2rem
  }
  }
</style>
